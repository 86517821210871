
import { Component, Vue } from 'vue-property-decorator';
import {_download} from "@/utils/pageConfig/download/_download";
import { Foundation } from '@/utils/Foundation';
import API_Download from '@/utils/API/download/Download';

@Component({})
export default class extends Vue {
    /*表格相关*/
    pageConfig: any = _download;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };

    mounted() {
        this.getTableData();
    }

    /*获取表格数据*/
    async getTableData() {
        const res: any = await API_Download.getDownloadList(this.params);
        this.tableData = (res.records && res.records.length > 0 &&
            res.records.map((e: any) => {
                e._status = Foundation.findInArray(this.pageConfig.status, 'value', e.status).label;
                e._createTime = Foundation.dateFormat(e.createTime);
                return e;
            })) || [];
        this.total = res.total
    }

    /*底部分页切换*/
    currentChange(page_no: number) {
        this.params.current = page_no
        this.getTableData()
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*下载*/
    async handleDownload(downloadUrl: string) {
        window.open(downloadUrl, '_blank')
    }
}
