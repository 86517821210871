//状态
const status: any = [
    {
        label: '导出中',
        value: '0',
    },
    {
        label: '已完成',
        value: '1',
    },
    {
        label: '导出失败',
        value: '2',
    },
    {
        label: '空数据',
        value: '3',
    },
    {
        label: '已过期',
        value: '4',
    }
];

export const _download = {
    //列表
    table: [
        {
            prop: 'fileName',
            label: '文件名',
        },
        {
            prop: '_status',
            label: '状态',
        },
        {
            prop: '_createTime',
            label: '导出时间',
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
    status: status
};